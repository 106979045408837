<template>
  <v-container id="forgot" fluid tag="section" style="padding-bottom: 150px">
    <section>
      <v-row justify="center">
        <v-col cols="12" md="6">
          <base-material-card>
            <template v-slot:heading>
              <div class="text-h3 font-weight-light">Forgot Password</div>
            </template>
            <validation-observer v-slot="{ invalid }">
              <v-form @submit.prevent="forgot">
                <v-container class="py-0">
                  <v-row>
                    <v-col cols="12" md="12">              
                      <v-alert    v-show="completed" dense
                                  type="success"
                                  >
                          Password has been Reset. Please check your email.
                      </v-alert>
                      <v-alert    v-show="errorOccurred" dense
                                  type="error"
                                  >
                          An error occurred attempting to reset the password.
                      </v-alert>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="12">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Email"
                        rules="required|email"
                      >
                        <v-text-field
                          v-model="email"
                          type="email"
                          label="Email Address"
                        />
                        <span class="warning--text">{{ errors[0] }}</span>
                      </validation-provider>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="3" md="3">
                      <v-btn
                        color="primary"
                        class="ml-0"
                        :disabled="invalid"
                        type="submit"
                      >
                        <v-icon class="mr-2" dark> mdi-send </v-icon>
                        Reset Password
                      </v-btn>
                    </v-col>
                    <v-col cols="6" md="6">
                    </v-col>
                    <v-col cols="3" md="3">
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </validation-observer>
          </base-material-card>
        </v-col>
      </v-row>
    </section>
  </v-container>
</template>

<script>
export default {
  name: "Forgot",
  data: () => ({
    email: null,
    completed: false,
    errorOccurred: false
  }),
  methods: {
    forgot: function (e) {
      this.completed = false;
      this.errorOccurred = false;
      this.$store
        .dispatch("forgot", {
          email: this.email
        })
        .then(() => {
          this.completed = true;
          //  this.$router.push({ path: "/pages/login" });
        })
        .catch(() => {
          this.errorOccurred = true;
        })
    },
  },
};
</script>
